import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldPioneer: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you build Pioneer?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_pioneer.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you build Pioneer?</h1>
          <h2>
            Not sure if building Pioneer is the right move? No worries! Check
            our review and guide for him!
          </h2>
          <p>
            Last updated: <strong>27/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Build? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="pioneer"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>You probably shouldn’t, but it’d be really funny if you did.</p>
        <p>
          Pioneer’s great if you want to see everyone’s chibis slide around in
          battle or if you want to theorycraft terrible meme builds, but that’s
          about it. He’s a real jack of all trades but master of none. He can
          tank!…with I2L50’s worth of bulk. He can buff!…for less than an AFK
          Mercuria. He can do damage!…with his 3* ATK stat.
        </p>
        <p>
          But hey, he’s free and his kit is fun. If you’ve got some spare
          change, why not give it to our friendly Awakened door-to-door
          salesman?
        </p>
        <SectionHeader title="Lore" />
        <p>
          One night, several people broke into a suit store in San Francisco and
          spray painted all over the mannequin in the front window display. The
          expensive Gekko & Flussers suit on display was completely ruined—at
          least, that’s what the owner thought as they threw the whole thing
          out, mannequin and all. The mannequin, however, Awakened through his
          appreciation for the bold artistry of the graffiti and became Pioneer.
          With a keen eye for trends, Pioneer now travels door-to-door among the
          wealthy to sell products with revolutionary style unseen among the
          rich—items made by the poor.
        </p>
        <SectionHeader title="Review" />
        <p>
          True to his name, this 3-star Spirit arcanist pioneers the ability to
          Shift his own position in battle. His [Roving Salesman] Insight I
          grants him different buffs depending on where he’s standing:
        </p>
        <ul>
          <li>Critical Rate +20%, Critical DMG +20%</li>
          <li>DMG Bonus +20%, DMG Reduction +20%</li>
          <li>DMG Reduction +20%, DMG Heal +20%</li>
          <li>
            After being attacked, reflects 30% of the DMG. Cannot exceed 200% of
            Max HP.
          </li>
        </ul>
        <p>
          As you can see, he gets more offense the further “forward” (left, on
          screen) he is, while he gets more defense the further “backward” he
          stands. His skills also follow this trend and allow him to slide
          around the battlefield. [Competitive Differentiation] deals
          single-target Mental DMG, then moves him forward. [To Future Business]
          is a [Counter] that grants him [Taunt], +25% Reality and Mental DEF,
          and moves him back 1 space at Rank 1. Finally, his Ultimate [Bridge
          the Gap] allows him to switch places with an ally, granting them the
          corresponding position buff from his Insight I for 1 round on top of a
          decent 2-round Shield.
        </p>
        <p>
          Pioneer’s design is a breath of fresh air and reflects a more
          experimental design space Bluepoch seems to be trying out, which is
          always great to see. However, his kit suffers greatly from the
          low-rarity I2L50 curse. At first glance, being able to give +20% Crit
          Rate/DMG on Ult seems like the goal. Get Pioneer to the front, then
          swap with a DPS in the back to do burst damage, right? It’s not that
          easy, though: to get to 5 Moxie, you need to cast 5 of his skills.
          Unless you want to Pioneer [Taunt]ed while he moves forward (and thus
          losing his defensive position buffs), his [Counter] is a relatively
          dead card. [Competitive Differentiation] doesn’t feel worth the AP
          either because of his low ATK. Notably, it moves him forward after he
          attacks, so he doesn’t immediately benefit from his offensive position
          buffs. When you finally do get to cast his Ultimate, the buffs from
          [Roving Salesman] only last 1 round. Not only that, but fellow 2.0
          character Mercuria gives that much Crit Rate and even more Crit DMG
          off of a skill and Insight passive.
        </p>
        <p>
          No, Pioneer is more of a defensive buffer. His [To Future Business]
          seems to incentivize a tank role, and he has a surprisingly solid 6053
          HP at max level—which beats out the squishiest of 6*, like 37. As a
          result, his Ultimate’s 2-round shield is pretty chunky. However, there
          isn’t much of a place for defensive buffers in the current meta, and
          if you need a tank, 1.9’s Kakania does a much, much better job.
        </p>
        <p>
          What is he good for, then? Meme builds, mostly. Pioneer is one of the
          few characters who can infinitely stack up Brave New World’s passive
          since neither his Ultimate nor his Skill 2 do damage, allowing you to
          make one big damage number if you can keep him alive long enough to
          Ult a bunch of times in a row. If you’ve ever wanted both sides of
          Avgust’s buffs, you can also do that now. Also, as badly as he is
          outclassed by Mercuria, his Crit buffs do stack with hers because
          they’re a unique [Pos Stat]. Finally, he can synergize with future
          position-related kits and mechanics, making him more viable down the
          line.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Very unusual kit with a lot of potential for future
                    synergies.
                  </li>
                  <li>
                    His [Roving Salesman] buffs stack with similar [Stats Up]
                    effects.
                  </li>
                  <li>
                    As a Spirit character with a very versatile and customizable
                    Ultimate buff, he can slot into just about any team.
                  </li>
                  <li>Free in 2.0!</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Low rarity means no Insight III passive and a general lack
                    of stats.
                  </li>
                  <li>
                    Jack of all trades, master of none: buffs are underwhelming,
                    attacks do little damage, and there are much better tanks.
                  </li>
                  <li>
                    Although he can fit in anywhere, he also isn’t the best at
                    anything—most of the time you’d rather be running someone
                    else.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          As a low-rarity character, Pioneer should be easy to P5 over time.
        </p>
        <p>
          His P3 increases the bulkiness of his Ultimate’s Shield to a whopping
          70% Max HP. His P5 increases his [Roving Salesman] Crit buffs to 30%
          each—not bad for something that can stack with generic buffs.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Any character that has a position-dependent kit works with
          Pioneer—which, at the moment, is just Avgust. As Avgust’s DMG Heal and
          DMG Dealt buffs do stack with Pioneer’s Insight I, this stylish
          mannequin can get the most out of Avgust’s kit with clever
          positioning. Pioneer’s got a lot to look forward to, though. 2.2’s
          White Rum gains [Rousing Morale] and [Sturdiness] when she changes
          position, so Pioneer’s shenanigans can allow her to stack up an absurd
          amount of each. Finally, as of 2.2 Pioneer is technically the only way
          to completely max out Lopera’s CR-to-CDmg conversion rate, which
          increases by 10% for each different kind of Bullet a character has.
          Some characters have 1 non-transferrable type and Duncan can give all
          allies 2 types, but since Lopera’s [Double Backups] gives Incantation
          Bullets to allies in front and Ritual Bullets to allies behind her,
          Pioneer is the only way you can get both on one character with native
          Bullet generation. (Does that make him viable for Lopera teams? Not
          really.)
        </p>
        <p>
          Pioneer’s afflatus and mix of offense and defense make him generally
          applicable to most teams. His Ultimate buff being short but stackable
          with generic [Stats Up] can be useful for characters like Windsong and
          Spathodea who have high burst damage and excess Crit Rate to Crit DMG
          conversion. As a character with access to [Taunt], he can also protect
          characters who don’t like getting hit. An honorable mention goes to
          the alternate boss in A Series of Dusks, who does more damage to
          characters in front; Pioneer can shuffle your team around so the
          damage spreads out more evenly.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="avgust"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="lopera"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="white-rum"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Notable Anti-Synergies" />
        <p>
          <strong>Echoes of Opera Raid:</strong> The [Special Guest] status is
          determined at the start of battle, and no amount of shuffling will
          change who has it.
        </p>
        <p>
          <strong>Characters who like getting hit:</strong>Ms. NewBabel and
          Desert Flannel can’t trigger their counter-attacks and ripostes if
          enemies go for Pioneer instead. Same goes for The Fool’s [Corrode].
        </p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Pioneer:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="the-fighters-best-wishes"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="a-free-heart"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="brave-new-world"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="beyond-wonderland"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          “The Fighter’s Best Wishes” is the best defensive option for Pioneer
          even though he doesn’t benefit from its Genesis DMG buff. As the
          strength of his Ultimate’s shield and his maximum reflected DMG from
          [After-sales Service] both scale off of Max HP, Pioneer can make great
          use of this psychube’s 16% HP substat. The -10% DMG Taken also helps
          his survivability.
        </p>
        <p>
          “A Free Heart” is a budget version of “The Fighter’s Best Wishes” with
          an 8% HP substat and -20% DMG Taken while under 50% HP. It suffers
          from lack of stats as most 5* psychubes do, but it’s cheap to level
          up.
        </p>
        <p>
          As mentioned above, Pioneer can also use “Brave New World” for a meme
          build, since its passive can stack with itself indefinitely as long as
          you don’t attack. His [Counter] also makes it so that you don’t only
          have to move his incantations to generate Moxie while stacking up BNW.
          Do note you’ll want to run a lot of meta supports just for that
          Pioneer screenshot damage.
        </p>
        <p>
          Finally, if you don’t have “The Fighter’s Best Wishes” but want to
          raise Pioneer’s Max HP, “Beyond Wonderland” is an OK choice. It gives
          16% HP but doesn’t have as synergistic of a passive as “The Fighter’s
          Best Wishes” does. Pioneer also doesn’t make much use of its 21% Crit
          Rate with his abysmally low 252 Crit Technique.
        </p>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/pioneer_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/pioneer_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/pioneer_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/pioneer_r_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>
          A few suggestions are listed below, but Pioneer is so flexible that
          you can throw him in just about any comp.
        </p>
        <p>
          3-People teams are highlighted, with the 4th slot added at the side to
          provide a 4-P variation of the same team or even insights on possible
          variations of the 3-P team. Note that most of Pioneer’s best teammates
          are going to be released in future versions, so some teams below will
          need substitutes for the time being.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            A Ship, a Mannequin, and a Boy Walk into a Disco…
            <span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="white-rum"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="pioneer"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="avgust"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>
                  Yenisei, Barbara (2.1), Sotheby, Any Other Healer, Lorelei
                </strong>
              </li>
              <li>An ultra budget team that maximizes sliding shenanigans.</li>
              <li>
                Pioneer can give White Rum [Rousing Morale] and [Sturdiness]
                just by moving around with his skills. Plus, he can help
                position White Rum at the very back to set up for her Ultimate
                if she slides out of place.
              </li>
              <li>
                As both White Rum and Pioneer like dealing more damage up front
                and taking less damage in the back, Avgust’s kit perfectly
                synergizes with them.
              </li>
              <li>
                Both Pioneer and Avgust have some survival capabilities, though
                it wouldn’t be a bad idea to add in a proper healer.
              </li>
              <li>
                Pre-2.2, you can run any DPS who likes +DMG Dealt and any of
                Pioneer’s buffs.
              </li>
              <li>
                In 4-P content, Yenisei makes for a synergistic healer as her
                [Sturdiness] stacks with White Rum’s and Avgust’s while her
                single-target heals can be used on Pioneer since he’ll be taking
                the bulk of the damage.
              </li>
              <li>
                2.1’s Barbara is another great 5* half-support, half-sustain
                like Avgust. She can rank up Avgust’s attack for dispelling
                purposes, increase Mental DMG (which Pioneer and Avgust do), and
                give everyone big shields to make up for the lack of sustain.
              </li>
              <li>
                With Avgust’s access to [Cure], Sotheby becomes a much more
                viable healer if you have her at I3. Any other healer works well
                too.
              </li>
              <li>
                Lorelei is a great budget buffer who doesn’t overlap with anyone
                except Avgust. Her [Weakness] debuff helps these squishy
                characters survive, and her Crit DMG and DMG Taken Reduction
                buffs stack with Pioneer’s [Roving Salesman]. However, since
                this team will mostly have Attack cards, her Insight I will
                grant +15% DMG Dealt most of the time. Avgust grants +DMG Dealt
                upon attacking, so you may want to save up the attack card for
                an enemy dispel instead.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            I Want to Use Pioneer in a Non-Meme Comp
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="windsong"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="pioneer"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>Kakania, Mercuria, Semmelweis, Isolde</strong>
              </li>
              <li>For those who want to use Pioneer as a generic support.</li>
              <li>
                Run Pioneer as a tank and slowly scooch him up to the front as
                he reaches full Moxie. Upon his Ultimate, have him swap with
                Windsong to give her +20% Crit Rate and Crit DMG for a turn.
              </li>
              <li>
                With her short burst window and Excess-Crit-Rate-to-Crit-DMG
                conversion, Windsong can take full advantage of Pioneer’s potent
                1-turn buff. She also doesn’t mind Pioneer taking up AP as much
                as other DPS characters since she needs time to stack up her
                Skill 2’s cards.
              </li>
              <li>
                In 4-P content, Kakania can double Windsong’s damage and help
                Pioneer survive.
              </li>
              <li>
                Mercuria is also a very potent option. Since Pioneer’s [Roving
                Salesman] and Vila’s [Song of Passion] are [Pos Status]es, they
                can stack with Mercuria’s generic Crit Rate and Crit DMG [Stats
                Up] buffs. With Mercuria’s Rank 3 [Self Focusing] and Crit DMG
                buff at 9 [Cosmic Energy], the three characters can add a
                combined +80% Crit Rate and +90% Crit DMG to Windsong during her
                burst turn if done right.
              </li>
              <li>
                Semmelweis can offer a much-needed DMG Dealt buff to avoid
                diminishing returns on Crit DMG, while still offering a good
                +15% Crit Rate through [Fresh Blood].
              </li>
              <li>
                Another option is to run Spathodea and Isolde, as Spathodea also
                has Excess-Crit-Rate-to-Crit-DMG conversion. Vila’s buff is less
                potent on Reality characters, though.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Bulletmaxxing <span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mr-duncan"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lopera"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                Bulletmaxxer: <strong>Pioneer</strong>
              </li>
              <li>
                For people who really, really want to have 5 [Bullet] types in
                Lopera’s Array—no matter the cost.
              </li>
              <li>
                As mentioned earlier, Lopera’s Array caps out at 5 [Bullet]
                types, and the only way to get all 5 on a character as of 2.2 is
                Pioneer. The goal is to get them all onto Lucy. (Tennant and
                Mondlicht technically also work for this, if you don’t have Lucy
                and want to suffer through this comp anyway).
              </li>
              <li>Lucy gets [Charged Ammunition] on her Ultimate.</li>
              <li>
                Mr. Duncan gives [Acid Bullet] and [Shrapnel Bullet] to all
                teammates using his [Counter]. (Note that Pioneer’s [Taunt] may
                interfere with Mr. Duncan’s [Counter] riposte if enemies only
                have single-target attacks.)
              </li>
              <li>
                Lopera grants all allies in front of her [Incantation Bullet]s
                and all allies behind her [Ritual Bullet]s. Deploy Lucy on one
                side of Lopera and Pioneer on the other. After Lucy gets bullets
                from Lopera, have Pioneer use his Ultimate to switch places with
                Lucy so she can get the other type (despite [Ritual Bullet]
                being entirely useless to her).
              </li>
              <li>
                Deploy Lopera’s Array and start killing things using Lucy, armed
                with the knowledge that you squeezed 10% more
                excess-Crit-Rate-to-Crit-DMG conversion from Lopera by using
                Pioneer.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldPioneer;

export const Head: React.FC = () => (
  <Seo
    title="Should you build Pioneer? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if building Pioneer is the right move? No worries! Check our review and guide for him!"
    game="reverse"
  />
);
